export interface ErrorPanelProps {
  error: Error;
  children?: React.ReactNode;
}

export const UnknownErrorWidget: React.FC<ErrorPanelProps> = ({ error }) => {
  console.error(error);
  return (
    <div className='px-4'>
      <div className='p-10 bg-[#FEF7F9] border-red-300 border-2 rounded-lg m-6 max-w-2xl mx-auto'>
        <h1 className='text-2xl font-bold mb-4'>Algo salió mal</h1>
        <p>
          No pudimos procesar tu solicitud. Recibimos el siguiente mensaje:
          <pre className='mt-4 p-4 bg-white border rounded-md whitespace-pre-wrap'>{error.message}</pre>
        </p>
        <p className='mt-4'>
          Por favor, inténtalo de nuevo más tarde o ponte en contacto con el administrador de este sistema.
        </p>
      </div>
    </div>
  );
};
